$(document).ready(function () {
	animationLoader();
	setBackgroundElement();
	header.init();
	// header
	mappingMenu();
	// swiper
	swiperInit();
});
function setBackgroundElement() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-size": "cover",
			"background-position": "center center",
			"background-image": "url(" + background + ")",
		});
	});
}
/*==================== Mapping Menu ====================*/
function mappingMenu() {
	let ww = $(window).width();
	if (ww < 768) {
		$('.btn-back-home').appendTo('header .list-menu')
		$('.btn-contact').appendTo('header .list-menu')
	}

}
/*==================== Animation loader  ====================*/
function animationLoader(e) {
	$("body").addClass("loaded");
}

/*==================== Append Click Scroll Tab ====================*/
function clickScrollTab() {
	$(".wrap-tab-link ul").scrollTo("li.active", 800);
	let numberTab = $(".tabslet-tab li").length;
	let countNumber = 1;
	if (numberTab > 2) {
		$(".tabslet-tab").wrap('<div class="number-tab-wrap"></div>');
		$(".number-tab-wrap").append(
			'<div class="icon-next"><div class="next"><em class="material-icons">navigate_next</em></div></div>'
		);
		$(".icon-next").click(function () {
			if (countNumber < numberTab - 1) {
				$(this)
					.parent()
					.find(".tabslet-tab")
					.scrollTo("li:eq(" + countNumber + ")", 800);
				countNumber++;
			} else {
				countNumber = 1;
				$(this).parent().find(".tabslet-tab").scrollTo("li:eq(0)", 800);
			}
		});
	}
}
/*==================== JS Other ====================*/
function height(el) {
	var height = 0;
	$(el).each(function () {
		var thisHeight = $(this).height();
		if (thisHeight > height) {
			height = thisHeight;
		}
		setTimeout(() => {
			$(el).height(height);
		}, 100);
	});
}

let header = {
	scrollActive: function () {
		let height = $("header").height();
		if ($(window).scrollTop() > height) {
			$("header").addClass("active");
		} else {
			$("header").removeClass("active");
		}
	},
	hambuger: function () {
		$('.hambuger').click(function () {
			$(this).toggleClass('active')
			$('.list-menu').toggleClass('isOpen')
		})
	},
	init: function () {
		header.hambuger();
	}
};
/*==================== Swiper ====================*/
function swiperInit() {
	var bannerHome = new Swiper(".banner-home .swiper-container", {
		slidesPerView: 1,
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 300,
		// slidesPerColumn: 2,
		// spaceBetween: 30,
		slidesPerColumnFill: "row",
		pagination: {
			el: ".banner-home .swiper-pagination",
			clickable: true,
		},
		navigation: {
			nextEl: ".banner-home .button-next",
			prevEl: ".banner-home .button-prev",
		},
	});
	var bannerHome = new Swiper(".section-home-2 .swiper-container", {
		slidesPerView: 1.2,
		spaceBetween: 15,
		autoplay: {
			delay: 5000,
		},
		speed: 300,
		slidesPerColumnFill: "row",
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			}
		}
	});
}

$(document).on("scroll", function () {
	header.scrollActive();
});
